const intro = document.querySelector(".splash");
const logo = document.querySelector(".logo-header");
const logoSpan = document.querySelectorAll(".logo");
const logoHighlight = document.querySelector(".logo-highlight");

const timeout = (time) => new Promise((resolve) => setTimeout(resolve, time));

const activateSpan = (span, index) =>
  timeout(index * 50).then(() => {
    span.classList.add("active");
  });

const deactivateSpan = (span, index) =>
  timeout(index * 50).then(() => {
    span.classList.remove("active");
    span.classList.add("fade");
  });

const handleLoad = async () => {
  await timeout(200);

  logoSpan.forEach(activateSpan);

  await timeout(700);

  logoHighlight.classList.add("logo-green");

  await timeout(1200);

  logoSpan.forEach(deactivateSpan);

  await timeout(200);

  intro.style.top = "-100vh";
};

window.addEventListener("DOMContentLoaded", handleLoad);